form label {
  display: none;
}

form input[type="text"], form input[type="password"], form input.btn {
  border-radius: 0;
  border-style: none;
  width: 70%;
}

form #edit-name {
  margin-top: 15px;
  background-color: #ececec;
  border-color: #ececec;
}

form #edit-pass {
  margin-top: 25px;
  background-color: #ececec;
  border-color: #ececec;
}

form #edit-actions {
  margin-top: 30px;
  margin-bottom: 25px;
  background-color: transparent;
}

form .password-reset {
  width: 100%;
  text-align: center;
  font-size: 16px;
}

form .additional-content {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
}

form #edit-submit {
  background-color: #c32745;
  text-transform: none;
  font-weight: bold;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 768px) {
  form #edit-submit {
    width: 70%;
  }
}
